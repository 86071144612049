import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Image, Container, Icon } from "@atoms";
import {
  m,
  AnimatePresence,
  useMotionValue,
  useMotionTemplate,
} from "framer-motion";
import { useWindowSize } from "@utils";
import { useAppState } from "@state";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import ImageModalContainer from "./ImageModalContainer";

const StoriesOfChangeCarousel = ({ stories, progress, unfold }) => {
  const [active, setActive] = useState(0);
  const [size, setSize] = useState(18);
  const x = useMotionValue(0);
  const spacing = useMotionTemplate`${x}rem`;
  const lang = useLang();

  const [, dispatch] = useAppState();
  const setModalContent = idx => {
    dispatch({
      type: "openModal",
      allowScroll: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: () => (
        <ImageModalContainer modalImages={stories} initial={idx} />
      ),
    });
  };

  const { innerWidth: windowWidth } = useWindowSize();

  const screens = {
    xs: "350px",
    sm: "700px", // bigger than most phones
    md: "850px",
    lg: "1200px",
    xl: "1600px", // larger than 15" macbook pro
  };

  const calculatePortalSize = width => {
    const screenNumbers = {};
    Object.keys(screens).map(screen => {
      if (typeof screens[screen] === "string") {
        screenNumbers[screen] = parseInt(screens[screen].replace("px", ""), 10);
      }
      return true;
    });
    // configure number of slides based on screen size
    const sizes = {
      xs: 14,
      sm: 14,
      md: 14,
      lg: 18,
      xl: 18,
    };
    // match screen
    const matchedScreen = Object.keys(screenNumbers).find(screen => {
      return width < screenNumbers[screen];
    });

    // return match
    if (matchedScreen && sizes[matchedScreen]) {
      return sizes[matchedScreen];
    }

    return sizes.sm;
  };

  useEffect(() => {
    const newSize = calculatePortalSize(windowWidth);
    setSize(last => {
      if (last !== newSize) {
        x.set(active ? active * (newSize - 3.5) * -1 : 0);
        return newSize;
      }
      return last;
    });

    x.set(active ? active * (size - 3.5) * -1 : 0);
  }, [active, windowWidth]);

  return (
    <Container className="relative" frame>
      {/* prev button */}
      <AnimatePresence>
        {unfold && (
          <m.button
            type="button"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onClick={() => setActive(last => (last > 0 ? last - 1 : last))}
            className="group absolute inset-y-0 -left-4 z-20 my-auto hidden h-16 w-16 shrink-0 items-center justify-center rounded-full border-2 border-white p-3 transition duration-300 hover:scale-105 sm:flex md:left-4"
          >
            <Icon
              name="arrow"
              className="h-4 w-4 rotate-180 text-white transition duration-300 group-hover:scale-110"
              fitHeight
            />
          </m.button>
        )}
      </AnimatePresence>

      {/* carousel body */}
      <m.div
        className={classNames(
          "relative flex transition-transform duration-500 ease-in-out",
          {
            "items-center justify-center": !unfold,
            "items-end justify-start": unfold,
          }
        )}
        style={{ x: unfold ? spacing : 0 }}
      >
        {stories.map((story, i) => (
          <m.div
            className={classNames("aspect-portal shrink-0", {
              absolute: !unfold,
              "relative -mr-14": unfold,
              "z-0 w-[14rem] md:w-[18rem]": !unfold || (unfold && active !== i),
              "z-10 w-[16rem] md:w-[20rem]": unfold && active === i,
            })}
            whileHover={unfold ? { scale: 1.05 } : {}}
            layout
          >
            <m.div
              className={classNames(
                "relative h-full w-full overflow-hidden rounded-t-full bg-gradient-to-b from-40% to-transparent transition-all",
                {
                  "border-2 border-white from-transparent": !unfold,
                  "border-0 border-transparent from-light-green": unfold,
                  "opacity-40": unfold && active !== i,
                }
              )}
            >
              <button
                type="button"
                onClick={() => {
                  if (active !== i) {
                    setActive(i);
                  } else {
                    setModalContent(i);
                  }
                }}
                className={classNames("absolute inset-0 z-10 h-full w-full", {
                  "pointer-events-none": !unfold,
                })}
                aria-label={t("Expand Story", lang)}
              >
                <div
                  className={classNames(
                    "absolute inset-0 h-full w-full transition-opacity",
                    {
                      "opacity-0": !unfold || (unfold && active !== i),
                    }
                  )}
                >
                  <Image image={story?.image} fill sourceArray={[1100, 3600]} />
                </div>
              </button>
            </m.div>
            <button
              type="button"
              onClick={() => {
                if (active !== i) {
                  setActive(i);
                } else {
                  setModalContent(i);
                }
              }}
              className={classNames(
                "absolute top-full mt-5 inline-block w-80 text-left text-xl font-bold text-white",
                { hidden: !unfold || (unfold && active !== i) }
              )}
            >
              {story.title}
            </button>
          </m.div>
        ))}
      </m.div>

      {/* next button */}
      <AnimatePresence>
        {unfold && (
          <m.button
            type="button"
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onClick={() =>
              setActive(last => (last < stories.length - 1 ? last + 1 : last))
            }
            className="group absolute inset-y-0 -right-4 z-20 my-auto hidden h-16 w-16 shrink-0 items-center justify-center rounded-full border-2 border-white p-3 transition duration-300 hover:scale-105 sm:right-16 sm:flex lg:right-10"
          >
            <Icon
              name="arrow"
              className="h-4 w-4 text-white transition duration-300 group-hover:scale-110"
              fitHeight
            />
          </m.button>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {unfold && (
          <m.ul
            className="mt-36 flex gap-2"
            initial={{ opacity: 0, y: 40 }}
            exit={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.4 } }}
            transition={{ ease: "circOut" }}
          >
            {stories.map((s, i) => (
              <li>
                <button
                  type="button"
                  aria-label={`Go to the ${s.title} slide`}
                  className={classNames(
                    "h-2 w-2 rounded-full border-2 border-white focus:bg-white/50",
                    {
                      "bg-white focus:bg-white": active === i,
                    }
                  )}
                  onClick={() => setActive(i)}
                />
              </li>
            ))}
          </m.ul>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default StoriesOfChangeCarousel;
