import React, { useState, useEffect } from "react";
import { ImageModalSlide } from "@molecules";
import { m, AnimatePresence } from "framer-motion";
import { useAppState } from "@state";

const ImageModalContainer = ({ modalImages, initial }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentPage, setCurrentPage] = useState(initial);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visible, setVisible] = useState(true);
  const [, dispatch] = useAppState();

  useEffect(() => {
    if (!visible) {
      dispatch({ type: "closeModal" });
    }
  }, [visible]);

  useEffect(() => {
    const handleKey = e => {
      const key = parseInt(e.keyCode, 10);
      // down arrow
      if (key === 40) dispatch({ type: "closeModal" });

      // left arrow
      if (key === 37)
        setCurrentPage(previousState =>
          previousState > 0 ? previousState - 1 : previousState
        );

      // right arrow
      if (key === 39)
        setCurrentPage(previousState =>
          previousState < modalImages.length - 1
            ? previousState + 1
            : previousState
        );
    };
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);

  return (
    <AnimatePresence initial>
      {visible && (
        <m.div
          key={modalImages[currentPage].uid}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute inset-0"
        >
          <ImageModalSlide
            {...modalImages[currentPage]}
            pageState={[currentPage, setCurrentPage]}
            visibleState={[visible, setVisible]}
            length={modalImages?.length}
          />
        </m.div>
      )}
    </AnimatePresence>
  );
};

ImageModalContainer.defaultProps = {};

export default ImageModalContainer;
